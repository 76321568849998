import React, { useContext, useEffect, useState } from 'react';
import i18next from 'i18next';
import { useLoader } from '../../LoaderProvider';
import { alertsDashboardData, axiosAdapter } from '../../utils';
import idConstants from '../../consts/idConstants';
import {
  Col,
  Container,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row
} from 'reactstrap';
import { SnackbarContext } from '../../layouts/Context/snackBarContext';
import styled from 'styled-components';
import SearchBar from '../components/SearchBar';
import { LuChevronFirst, LuChevronLast } from 'react-icons/lu';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';

const ContainerDiv = styled.div`
  margin: 2rem 1rem;
  font-family: 'Articulat CF Medium';
  font-size: 0.9rem;
  background: #2f2f2f;
  border-radius: 0.75rem;
  padding: 1rem;
  color: #e9e9e9;
`;

const PaginatedTableDiv = styled.div`
  overflow-y: scroll;
  /* Extra small devices (phones, 600px and down) */
  @media only screen and (max-width: 600px) {
    min-height: 63vh;
    max-height: 63vh;
  }
  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 600px) {
    min-height: 63vh;
    max-height: 63vh;
  }
  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) {
    min-height: 60vh;
    max-height: 60vh;
  }
  /* Large devices (laptops/desktops, 992px and up) */
  @media only screen and (min-width: 992px) {
    min-height: 58vh;
    max-height: 58vh;
  }
  /* Extra large devices (large laptops and desktops, 1200px and up) */
  @media only screen and (min-width: 1200px) {
    min-height: 53vh;
    max-height: 53vh;
  }
`;

const NoResultsText = styled.div`
  font-family: Articulat CF Medium;
  color: #777777;
  font-size: 1.25rem;
  display: flex;
  margin-top: 5rem;
  justify-content: center;
`;

const CustomPagination = (props) => {
  const {
    resultsUrl,
    headerRow,
    dataRows,
    results,
    setResults,
    paginationHeading,
    showPaginationHeading,
    searchBarPlaceholder,
    showSearchBar,
    paginationComponentName,
    clientId,
    publicEndpoint = ''
  } = props;
  const { showMessage } = useContext(SnackbarContext);
  const { isLoading, showLoader, hideLoader } = useLoader();
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = idConstants.pagination.itemsPerPage;
  const [totalItems, setTotalItems] = useState(0);
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const maxVisiblePages = idConstants.pagination.maxVisiblePages; // Number of visible pages on each side of currentPage
  const [previousSearchTerm, setPreviousSearchTerm] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState(searchTerm); // Debounced search term

  useEffect(() => {
    const searchTermHandler = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, 750);
    return () => {
      clearTimeout(searchTermHandler);
    };
  }, [searchTerm]);

  useEffect(() => {
    showLoader();
    getResults();
  }, [currentPage, debouncedSearchTerm]);

  const handlePageChange = (pageNumber) => setCurrentPage(pageNumber);

  const getResults = async () => {
    debouncedSearchTerm !== previousSearchTerm &&
      previousSearchTerm !== '' &&
      handlePageChange(1);
    let data;
    switch (paginationComponentName) {
      case 'Release Management':
      case 'Alerts Dashboard':
        data = {
          clientId: clientId,
          publicEndpoint:publicEndpoint,
          // To be removed - Release Management Dummy data
          // clientId: 'd8045800-f01b-423c-bab3-4ca72f24a68f'
          // clientId: '2e446803-c332-4dce-afdd-bd1fcdbbfaef'
          offset: currentPage - 1,
          limit: itemsPerPage,
        };
        break;
      case 'Deployments Dashboard':
        data = {
          offset: currentPage - 1,
          limit: itemsPerPage,
          searchKeyword: debouncedSearchTerm
        };
        break;
    }
    try {
      const resultsResponse = await axiosAdapter(
        idConstants.axiosMethods.post,
        resultsUrl,
        data
      );
      if (resultsResponse?.data?.code === 200) {
        const response = resultsResponse.data.data;
        setResults(response);
        setTotalItems(resultsResponse.data.totalCount);
        setPreviousSearchTerm(debouncedSearchTerm);
      } else showMessage(i18next.t('PLACEHOLDERS.SOMETHING_WENT_WRONG'));
      hideLoader();
    } catch (error) {
      hideLoader();
      console.log(error);
    }
  };

  const getPaginationItems = () => {
    const paginationItems = [];

    // First page
    paginationItems.push(
      <PaginationItem
        className={
          currentPage === 1
            ? 'pagination-item-active'
            : 'pagination-item-passive'
        }
        key={1}
      >
        <PaginationLink onClick={() => handlePageChange(1)}>1</PaginationLink>
      </PaginationItem>
    );

    // Show ellipsis if necessary between first and visible pages
    if (currentPage > maxVisiblePages + 2) {
      paginationItems.push(
        <PaginationItem
          className='pagination-item-passive'
          key='ellipsis-1'
          disabled
        >
          <PaginationLink>...</PaginationLink>
        </PaginationItem>
      );
    }
    // Pages around the current page
    const startPage = Math.max(2, currentPage - maxVisiblePages);
    const endPage = Math.min(totalPages - 1, currentPage + maxVisiblePages);

    for (let i = startPage; i <= endPage; i++) {
      paginationItems.push(
        <PaginationItem
          className={
            currentPage === i
              ? 'pagination-item-active'
              : 'pagination-item-passive'
          }
          key={i}
        >
          <PaginationLink onClick={() => handlePageChange(i)}>
            {i}
          </PaginationLink>
        </PaginationItem>
      );
    }

    // Show ellipsis if necessary between last visible and last page
    if (currentPage < totalPages - maxVisiblePages - 1) {
      paginationItems.push(
        <PaginationItem
          className='pagination-item-passive'
          key='ellipsis-2'
          disabled
        >
          <PaginationLink>...</PaginationLink>
        </PaginationItem>
      );
    }

    // Last page
    if (totalPages > 1) {
      paginationItems.push(
        <PaginationItem
          className={
            currentPage === totalPages
              ? 'pagination-item-active'
              : 'pagination-item-passive'
          }
          key={totalPages}
        >
          <PaginationLink onClick={() => handlePageChange(totalPages)}>
            {totalPages}
          </PaginationLink>
        </PaginationItem>
      );
    }

    return paginationItems;
  };

  const onClearSearchbar = () => handlePageChange(1);

  return (
    !isLoading && (
      <ContainerDiv>
        <Container fluid className='pagination-container'>
          <Row className='heading-row'>
            {showPaginationHeading && (
              <Col className='title-col'>{i18next.t(paginationHeading)}</Col>
            )}
            {showSearchBar && (
              <Col className='searchbar-wrapper'>
                <SearchBar
                  value={searchTerm}
                  setValue={setSearchTerm}
                  placeholderText={searchBarPlaceholder}
                  margin='0.5rem'
                  width='15rem'
                  border='1px solid #b0b0b0'
                  color='#b0b0b0'
                  onClearSearchbar={onClearSearchbar}
                />
              </Col>
            )}
          </Row>
          {headerRow}
          <PaginatedTableDiv>
            {results?.length ? (
              dataRows
            ) : (
              <NoResultsText>
                {i18next.t('PAGINATION.NO_RESULTS')}
              </NoResultsText>
            )}
          </PaginatedTableDiv>
          {(paginationComponentName === 'Deployments Dashboard' || paginationComponentName === 'Alerts Dashboard') && (
            <Row>
              <Col
                style={{ visibility: !results?.length && 'hidden' }}
                className='entry-margin'
              >
                {totalItems} {i18next.t('PAGINATION.ENTRIES')}
              </Col>
              <Col
                style={{ visibility: !results?.length && 'hidden' }}
                className='pagination-col'
              >
                <Pagination>
                  <PaginationItem
                    className={
                      currentPage === 1
                        ? 'pagination-item-passive'
                        : 'pagination-item-active'
                    }
                    disabled={currentPage === 1}
                  >
                    <PaginationLink
                      first
                      onClick={() => handlePageChange(1)}
                      className='pagination-link'
                    >
                      <LuChevronFirst />
                    </PaginationLink>
                  </PaginationItem>
                  <PaginationItem
                    className={
                      currentPage === 1
                        ? 'pagination-item-passive'
                        : 'pagination-item-active'
                    }
                    disabled={currentPage === 1}
                  >
                    <PaginationLink
                      previous
                      onClick={() => handlePageChange(currentPage - 1)}
                      className='pagination-link'
                    >
                      <IoIosArrowBack />
                    </PaginationLink>
                  </PaginationItem>

                  {getPaginationItems()}

                  <PaginationItem
                    className={
                      currentPage === totalPages
                        ? 'pagination-item-passive'
                        : 'pagination-item-active'
                    }
                    disabled={currentPage === totalPages}
                  >
                    <PaginationLink
                      next
                      onClick={() => handlePageChange(currentPage + 1)}
                      className='pagination-link'
                    >
                      <IoIosArrowForward />
                    </PaginationLink>
                  </PaginationItem>
                  <PaginationItem
                    className={
                      currentPage === totalPages
                        ? 'pagination-item-passive'
                        : 'pagination-item-active'
                    }
                    disabled={currentPage === totalPages}
                  >
                    <PaginationLink
                      last
                      onClick={() => handlePageChange(totalPages)}
                      className='pagination-link'
                    >
                      <LuChevronLast />
                    </PaginationLink>
                  </PaginationItem>
                </Pagination>
              </Col>
            </Row>
          )}
        </Container>
      </ContainerDiv>
    )
  );
};

export default CustomPagination;
