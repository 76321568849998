import React, { lazy, Suspense, useEffect, useState } from 'react';
import { TabContent, TabPane, Row, Container } from 'reactstrap';
import styled from 'styled-components';
import { ReactComponent as BackIcon } from '../../assets/images/Back_Arrow.svg';
import i18next from 'i18next';
import Typography from '../../components/common/_typography';
import { useHistory } from 'react-router-dom';
import { ReactComponent as ReleaseIcon } from '../../assets/images/release.svg';
import { ReactComponent as ReleaseIconActive } from '../../assets/images/release_active.svg';
import { ReactComponent as AlertsIcon } from '../../assets/images/alert.svg';
import { ReactComponent as AlertsIconActive } from '../../assets/images/alert_active.svg';
import { ReactComponent as InfoIcon } from '../../assets/images/info.svg';
import { ReactComponent as InfoIconActive } from '../../assets/images/info_active.svg';
const InfoDashboardTab = lazy(() => import('./InfoDashboard'));
const AlertsDashboardTab = lazy(() => import('./AlertsDashboard'));
const ReleaseManagementTab = lazy(() => import('./ReleaseManagement'));

const ContainerDiv = styled.div`
  font-family: 'Articulat CF Medium';
  padding: 0.25rem 0;
  color: #e9e9e9;
  letter-spacing: 0.5px;
  margin: 0.5rem 0;
`;
const BackButtonDiv = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const TabDiv = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  border-bottom: 1px solid #373737;
`;

const TabTextDiv = styled.div`
  cursor: pointer;
  font-size: 1rem;
  margin: 0 0 -2px 1rem;
  padding-bottom: 5px;
`;

const TabIconTextDiv = styled.div`
  display: flex;
  align-item: center;
  cursor: pointer;
  font-size: 1.25rem;
  margin: 0 0 0 1rem;
  padding: 0 1rem 0.25rem 1rem;
`;

const BackIconStyled = styled(BackIcon)`
  margin-right: 1rem;
`;

const DeploymentDetails = () => {
  const history = useHistory();
  const [activeTab, setActiveTab] = useState('info');
  const locationState = history?.location?.state;
  useEffect(() => {
    // Access specific query parameters
    const queryParams = new URLSearchParams(location.search)?.get('tabId');
    queryParams && setActiveTab(queryParams);
  }, [location.search]);

  const toggle = (tab) => {
    history.push({
      pathname: location.pathname,
      state: {
        clientId: locationState?.clientId,
        publicEndpoint: locationState?.publicEndpoint
      },
      search: '?tabId=' + tab
    });
    setActiveTab(tab);
  };

  const onClickBackButton = () => history.push('/dashboard');

  return (
    <Container fluid style={{ padding: '0' }}>
      <ContainerDiv>
        <Row style={{ margin: '1rem 0' }}>
          <BackButtonDiv onClick={onClickBackButton}>
            <BackIconStyled />
            <Typography
              tag={'p-medium'}
              text={i18next.t('DEPLOYMENT_DETAILS.BACK_TO_ALL')}
            ></Typography>
          </BackButtonDiv>
        </Row>
        <TabDiv>
          <TabIconTextDiv
            style={{
              borderBottom: Boolean(activeTab === 'info')
                ? '1px solid #47ccd6'
                : '1px solid transparent'
            }}
            onClick={() => toggle('info')}
          >
            {activeTab === 'info' ? <InfoIconActive /> : <InfoIcon />}
            <TabTextDiv
              style={{
                color: activeTab === 'info' ? '#47ccd6' : '#e9e9e9'
              }}
            >
              {i18next.t('DEPLOYMENT_DETAILS.INFO_DASHBOARD')}
            </TabTextDiv>
          </TabIconTextDiv>
          <TabIconTextDiv
            style={{
              borderBottom: Boolean(activeTab === 'release')
                ? '1px solid #47ccd6'
                : '1px solid transparent'
            }}
            onClick={() => toggle('release')}
          >
            {activeTab === 'release' ? <ReleaseIconActive /> : <ReleaseIcon />}
            <TabTextDiv
              style={{
                color: activeTab === 'release' ? '#47ccd6' : '#e9e9e9'
              }}
            >
              {i18next.t('DEPLOYMENT_DETAILS.RELEASE_MANAGEMENT')}
            </TabTextDiv>
          </TabIconTextDiv>
          <TabIconTextDiv
            style={{
              borderBottom: Boolean(activeTab === 'alerts')
                ? '1px solid #47ccd6'
                : '1px solid transparent'
            }}
            onClick={() => toggle('alerts')}
          >
            {activeTab === 'alerts' ? <AlertsIconActive /> : <AlertsIcon />}
            <TabTextDiv
              style={{
                color: activeTab === 'alerts' ? '#47ccd6' : '#e9e9e9'
              }}
            >
              {i18next.t('DEPLOYMENT_DETAILS.ALERTS_DASHBOARD')}
            </TabTextDiv>
          </TabIconTextDiv>
        </TabDiv>
        <TabContent activeTab={activeTab}>
          <TabPane tabId={activeTab}>
            <Suspense>
              {activeTab === 'info' ? (
                <InfoDashboardTab />
              ) : activeTab === 'release' ? (
                <ReleaseManagementTab
                  clientId={locationState?.clientId}
                  publicEndpoint={locationState?.publicEndpoint}
                />
              ) : (
                <AlertsDashboardTab
                  clientId={locationState?.clientId}
                  publicEndpoint={locationState?.publicEndpoint}
                />
              )}
            </Suspense>
          </TabPane>
        </TabContent>
      </ContainerDiv>
    </Container>
  );
};

export default DeploymentDetails;
